import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'supply',
    meta: { title: "指标数据" },
    component: () => import('../views/supply.vue')
  },
  // {
  //   path: '/demand',
  //   name: 'demand',
  //   meta: { title: "需求图表" },
  //   component: () => import('../views/requirements.vue')
  // },
  {
    path: '/landscapeline',
    name: 'landscapeline',
    meta: { title: "指标数据" },
    component: () => import('../views/landscapeline.vue')
  },
  {
    path: '/heatmap',
    name: 'heatmap',
    component: () => import('../views/heatMap/heatmap.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  let title = '';
  if (to.meta && to.meta.title) {
    title = to.meta.title;
  }
  document.title = title;
})
export default router
