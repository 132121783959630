<template>
  <!-- 横屏组件，自定义标题，内容；输出关闭方法，resize方法 -->
  <div class="lanscreen">
    <div class="title">
      <div class="left">
        <el-divider direction="vertical"></el-divider>
        <slot name="left"></slot>
      </div>

      <slot name="center"></slot>

      <el-button @click="closeLandScreen" circle icon="el-icon-close" size="mini"></el-button>
    </div>

    <slot name="content" class="content"></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    window.addEventListener("resize", this.forceLandscapeScreenHandl);
  },
  methods: {
    forceLandscapeScreenHandle() {
      this.$emit("screenResize");
    },
    closeLandScreen() {
      this.$emit("closeLandScreen");
    }
  }
};
</script>
<style scoped lang="scss">
.lanscreen {
  width: 100%;
  height: 100%;
}
@media screen and (orientation: portrait) {
  .lanscreen {
    position: absolute;
    width: 100vh;
    height: 100vw;
    top: 0;
    left: 100vw;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    transform-origin: 0% 0%;
  }
}

@media screen and (orientation: landscape) {
  .lanscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: inline-flex;
    align-items: center;
  }
  h5 {
    font-size: 2vw;
    margin-bottom: 0;
  }
  button {
    background-color: #e0e0e0;
    color: #fff;
    width: 17px;
    height: 17px;
    padding: 0px;
  }
  .el-button:focus,
  .el-button:hover {
    border: none;
  }
  .el-button:active {
    border: none;
    background: #e0e0e0 !important;
    font-weight: bold;
    box-shadow: 0px 0px 6px #e0e0e0;
  }
}
.content {
  height: 90%;
}
.el-divider--vertical {
  width: 4px;
  height: 20px;
  border-radius: 2px;
  background: linear-gradient(180deg, #5467fe 0%, #7f8dff 100%);
}
.lanscreen {
  padding: 10px;
}
// ::v-deep .el-icon-arrow-up:before {
//   content: "\e790";
// }

// @media screen and (orientation: portrait) {
//   .lanscreen {
//     position: absolute;
//     width: 100vh;
//     height: 100vw;
//     top: 0;
//     left: 100vw;
//     -webkit-transform: rotate(90deg);
//     -moz-transform: rotate(90deg);
//     -ms-transform: rotate(90deg);
//     transform: rotate(90deg);
//     transform-origin: 0% 0%;
//   }
// }

// @media screen and (orientation: landscape) {
//   .lanscreen {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100vw;
//     height: 100vh;
//   }
// }
</style>