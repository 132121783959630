<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { login } from "@/service";
export default {
  data() {
    return {};
  },
  created() {
    // this.getUrlParam('token')
    console.log(this.getUrlParam('token'));
    const token=this.getUrlParam('token')
    //!localStorage.getItem("token") && localStorage.setItem("token",token);
    localStorage.setItem("token",token);
    !localStorage.getItem("token") && this.dologin();
    console.log(localStorage.getItem("token"))
  },
  methods: {
    dologin() {
      const params = {
        account: "fjgov",
        password: "123456",
        tenantId: "000000"
      };
      login(params).then((res) => {
        const { token } = res.data;
        localStorage.setItem("token", token);
      });
    },
     getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.href.split("?")[1].match(reg);
      var strValue = "";
      if (r != null) {
        strValue = unescape(r[2]);
      }
      return strValue;
    },
  }
};
</script>