import axios from 'axios';
import qs from "qs";
import { Message } from 'element-ui';
import router from '@/router';


const service = axios.create({
  //baseURL: 'http://129.204.60.70:7777/',
    baseURL: 'https://eco.huochaidata.cn/api/',
  // timeout: 5000,//请求超时时间
  // responseType:'json',//响应类型

  //是否该使用类似cookies,authorization headers(头部授权)或者TLS客户端证书这一类资格证书来创建一个跨站点访问控制（cross-site Access-Control）请求。
  //在同一个站点下使用withCredentials属性是无效的。
  // withCredentials: true, 
  // headers: {
  //   "Content-Type": "application/json;charset=utf-8"//请求头相关配置;
  // }
})

//请求拦截
service.interceptors.request.use(config => {
  // 携带请求头Authorization
  config.headers.Platform = "H5";
  if (!config.url.includes('login')) {
    config.headers.Token = localStorage.getItem('token')
  }
  // if (config.method === 'get') {
  //   // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2,不转换会显示为arr[]=1&arr[]=2
  //   config.paramsSerializer = function (params) {
  //     return qs.stringify(params, { arrayFormat: 'repeat' })
  //   }
  // }
  return config
}, err => {
  //错误处理
  return Promise.reject(err);
});

//响应拦截
service.interceptors.response.use(response => {
  // if (response.status === 200) {
  // 	//返回成功之后的处理
  // 	return Promise.resolve(response);
  // } else {
  // 	return Promise.reject(response);
  // }
  return response;
}, err => {
  // console.log('axios error log', err.response)
  const { status } = err.response || {};
  // 未登录
  if (status == 401) {
    router.replace({ path: '/auth/login' });
  } else if (status == 404) { // 404
    router.replace({ path: '/pages/error404' });
  } else {
    Message({
      showClose: true,
      // message: codeMessage[status],
      message: "系统异常",
      type: 'error',
      duration: 2 * 1000
    })
  }
  return Promise.reject(err.response);
})

//get请求
export function get(url, params) {
  return new Promise((resolve, reject) => {
    service({
      method: 'get',
      url,
      params: params,
      // cancelToken: new CancelToken(c => {
      // 	cancel = c
      // })
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}
//post请求
export function post(url, params) {
  return new Promise((resolve, reject) => {
    service({
      method: 'post',
      url,
      data: params,
      // cancelToken: new CancelToken(c => {
      // 	cancel = c
      // })
    }).then(res => {
      // if (res.data.code === 401) {
      // 	alert('登录已超时，请重新登陆！')
      // 	this.$router.push('/login')
      // }
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}
