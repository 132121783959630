// element-ui按需引入集成
import {
  Button,
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Menu,
  Divider,
  MenuItem,
  Avatar,
  Popover,
  Select,
  Option,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  CheckboxButton,
  RadioButton,Radio,Message,MessageBox,Input

} from 'element-ui'
const element = {
  install: function (Vue) {

    Vue.use(Button)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Menu)
    Vue.use(MenuItem)
    Vue.use(DatePicker)

    Vue.use(Checkbox)
    Vue.use(CheckboxGroup)
    Vue.use(RadioGroup)
    Vue.use(RadioButton)
    Vue.use(CheckboxButton)

    Vue.use(Dropdown)
    Vue.use(DropdownItem)
    Vue.use(DropdownMenu)
    Vue.use(Avatar)
    Vue.use(Popover)
    Vue.use(Divider)
    Vue.use(Radio)
    Vue.use(Input)
    Vue.component(MessageBox.name, MessageBox);
    Vue.prototype.$message = Message
    Vue.prototype.$confirm = MessageBox.confirm
    Vue.prototype.$alert = MessageBox.alert

  }
}
export default element
