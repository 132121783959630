import { get, post } from '../request.js';

/**
 * 登陆
 */
export async function login(params) {
  return post('/login/pwd', params);
}

/**
 * 获取一级指标
 */
export async function firstClassify(params) {
  return get('/target/main/classify', params)
}
/**
 * 获取一级指标（供给）
 */
export async function firstSupplyClassify(params) {
  return get('/target/main/supply/classify', params)
}
/**
 * 获取一级指标（需求）
 */
export async function firstDemandClassify(params) {
  return get('/target/main/demand/classify', params)
}
/**
 * 获取二级指标
 */
export async function subClassify(id) {
  return get(`/target/search/classify/${id}`)
}

/**
 * 根据二级指标获取可用年份
 */
export async function getYearByCode(secondCode) {
  return get(`/target/year/data/${secondCode}`)
}
/**
 * 指标折线图(一根)数据
 */
export async function queryOneLine(params) {
  return post('/target/one/line/data', params)
}
/**
 * 指标折线图(多根)数据
 */
export async function queryMoreLine(params) {
  return post('/target/more/line/data', params)
}
/**
 * 指标折线图(多折线图)数据
 */
export async function queryMoreChart(params) {
  return post('/target/more/table/data', params)
}
// 人口流动热力图（流入日均值）
export async function populationMoveIn(params){
  return post('/target/population/move/in',params)
}
// 人口流动热力图（流出日均值）
export async function populationMoveOut(params){
  return post('/target/population/move/out',params)
}
// 人口流动热力图（流动日均值）
export async function populationMoveAvg(params){
  return post('/target/population/move/avg',params)
}

